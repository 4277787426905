import {capitalize} from "@/main";

function getMonthName(date) {
    // Return name of the month for the date in parameter as label
    // and the number of the month as id (add 1 because in js months begin at 1)
    return {id: date.getMonth() + 1, label: capitalize(date.toLocaleString('fr-FR', {month: 'long'}))}
}

export function getMonth(monthId) {
    let date = new Date()
    date.setMonth(monthId-1)
    return date.toLocaleString('fr-FR', {month: 'long'})
}
export function getPreviousMonths(numMonth) {
    // Return array of X last month
    // X = numMonth = number of months to return
    const previousMonth = new Date();
    previousMonth.setDate(1)
    let months = [getMonthName(previousMonth)]

    for (let i = 0; i < numMonth - 1; i++) {
        previousMonth.setMonth((previousMonth.getMonth() - 1 + 12) % 12);
        months.push(getMonthName(previousMonth))
    }
    return months
}

