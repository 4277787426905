<template>
  <main>
    <div class="main-container">
      <h3 class="custom-title text-center text-primary mt-5">Authentification</h3>

      <dismiss-alert ref="errorAlert" msg="Le mot de passe ou le nom d'utilisateur est incorrect"></dismiss-alert>

      <!-- Form Command -->
      <b-form id="authForm">
        <p class="text-primary">Nom d'utilisateur</p>
        <b-form-input v-model="form.username" placeholder="Utilisateur"></b-form-input>
        <p class="text-primary">Mot de passe</p>
        <PasswordField @update-data="updatePassword" class="mt-2"></PasswordField>
        <b-button class="mt-3" variant="primary" type="button" @click="connect"> Connexion</b-button>
      </b-form>

    </div>
  </main>
</template>

<script>

import {auth_user} from "@/services/ApiServices";
import DismissAlert from "@/components/basics/DismissAlert.vue";
import {set_cookies} from "@/utils/Cookies";
import PasswordField from "@/components/PasswordField.vue";

export default {
  name: "auth-view",
  components: {PasswordField, DismissAlert},
  data: () => {
    return {
      form: {
        username: '',
        password: ''
      }
    }
  },
  methods: {
    updatePassword(n) {
      this.form.password = n
    },
    connect(){
      auth_user(this.form).then(response => {
        set_cookies(response)
        location.href = '/' // router.push not refresh component
      }).catch(error => {
        if (error.response.status == 401) {
          this.$refs.errorAlert.showAlert()
        }
        // TODO manage BACKEND KO
      })
    }
  }
}

</script>

<style scoped>
input {
  margin: 20px 0;
}

p {
  margin-bottom: 0px;
  margin-top: 10px;
}

input {
  margin: 10px 0;
}

form {
  background: #d3d3d39c;
  padding: 15px 20px;
  border-radius: 7%;
  max-width: 500px;
  margin: auto;
}
</style>