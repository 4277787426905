<template>
  <label class="w-100 d-flex">
    <b-form-input type="text" @input="updateData" v-model="input" :type="password_type"
                  placeholder="Mot de passe"/>
    <a @click="togglePassword" class="z-icon" style="cursor: pointer">
      <font-awesome-icon :icon="eyeComputedIcon"/>
    </a>
  </label>


</template>

<script>

const PASSWORD = 'password'
export default {
  name: "PasswordField",
  data() {
    return {
      input: '',
      password_type: PASSWORD,
      eyeIcon: 'fa-eye-slash'
    }
  },
  computed: {
    eyeComputedIcon() {
      return 'fa-solid ' + this.eyeIcon
    }
  },
  methods: {
    togglePassword() {
      if (this.password_type === PASSWORD) {
        this.password_type = 'text'
        this.eyeIcon = 'fa-eye-slash'
      } else {
        this.password_type = PASSWORD
        this.eyeIcon = 'fa-eye'
      }
    },
    updateData() {
      this.$emit('update-data', this.input);
    }
  }
}
</script>

<style scoped lang="scss">

.z-icon {
  z-index: 1;
  margin: 7px 0px 0px -29px;
  color: gray;
}

</style>