<template>
  <nav id="main-navbar" class="navbar navbar-expand-md navbar-light">

    <button class="navbar-toggler mr-2" type="button" @click="toggleNavbar" aria-controls="navContent"
            aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <p style="margin: auto 0;" class="navbar-brand mr-auto d-md-none">{{ username | capitalize }}</p>
    <div class="collapse navbar-collapse" id="navContent">

      <ul class="navbar-nav mr-auto w-100">
        <ul class="navbar-nav">
          <li :class="isSelected(item[1])" v-for="(item, index) in menu" :key="index + $route.fullPath">
            <!-- Force refresh of class with key-->
            <a @click="closeNavbar(item[1])" class="pointer nav-link">
              <font-awesome-icon :icon="item[2]"/>
              {{ item[0] }}
            </a>
          </li>
        </ul>

        <b-nav-item @click="logout()" id="logout">
          <font-awesome-icon icon="fa-solid fa-arrow-right-from-bracket"/>
          Déconnexion
        </b-nav-item>
      </ul>
    </div>
  </nav>

</template>


<script>

import {clear_cookies, get_username, is_boss} from "@/utils/Cookies";

export default {
  name: "home-navbar",
  data: () => {
    return {
      menu: [],
      username: ''
    }
  },
  methods: {
    logout() {
      clear_cookies()
      this.$router.push('/auth')
    },
    isSelected(url) {
      return url == window.location.pathname ? "selected" : "not-selected"
    },
    closeNavbar(to) {
      this.toggleNavbar();
      if (this.$route.path !== to) {
        this.$router.push(to);
      }
    },
    toggleNavbar() {
      const navbar = document.getElementById('navContent');
      if (navbar) {
        navbar.classList.toggle('show');
      }
    }
  },
  created() {
    const accueil = ['Accueil', '/', 'fa-solid fa-house']
    const user = ["Mon compte", '/account', 'fa-solid fa-user']
    if (is_boss()) {
      this.menu = [accueil, ["Clients", '/magasin', 'fa-solid fa-briefcase'], ["Salariés", '/user', 'fa-solid fa-users'], user]
    } else {
      this.menu = [accueil, user]
    }
    this.username = get_username()
  }
}
</script>

<style lang="scss">

@import "src/assets/styles/custom_variables";

#nav {
  border-bottom: 2px solid #d3d3d39c;
}

#nav .container {
  padding-top: 5px;
  padding-bottom: 5px;
  max-width: 100%;
}

.navbar-light .navbar-nav .nav-link {
  font-size: 17px;
  margin: 0px 18px;
  letter-spacing: 0.4px;
}

@media(max-width: 768px) {
  /* Appliquer la marge uniquement pour les petits écrans < md (=medium) */
  #navContent {
    margin-top: 10px;
  }
}

@media(min-width: 768px) {
  /* Appliquer la marge uniquement pour les grands écrans > md (=medium) */
  #logout {
    margin-left: auto;
  }
}

.navbar-light .navbar-nav .selected .nav-link {
  color: white;
}

.navbar {
  padding: 0.8rem;
}

.navbar-light .navbar-nav .not-selected .nav-link {
  color: $primary;
}

#main-navbar {
  border-bottom: solid 1px $light;
}

.selected {
  border-radius: 10px;
  background-color: rgba(0, 36, 84, 0.5);
}

.not-selected {
  background-color: white;
}

.pointer {
  cursor: pointer;
}
</style>