<template>
  <main>
    <div class="main-container">
      <div class="h-100">
        <b-button class="custom-btn" href="/magasin/create" variant="primary">
          <font-awesome-icon class="mr-2" icon="fa-solid fa-plus"/>
          Créer un client
        </b-button>
        <b-button class="custom-btn" href="/user/create" variant="primary">
          <font-awesome-icon class="mr-2" icon="fa-solid fa-plus"/>
          Créer un salarié
        </b-button>
        <!--b-button @click="download()" variant="primary">
          <font-awesome-icon icon="fa-solid fa-download"/>
          Exporter les données détaillées
        </b-button>
        <b-button @click="download_synthese()" variant="primary">
          <font-awesome-icon icon="fa-solid fa-download"/>
          Exporter la synthèse
        </b-button-->

        <!-- Export des données -->
        <div class="accordion" role="tablist">
          <b-card no-body>
            <b-card-header class="p-0" role="tab">
              <b-button v-b-toggle.accordion-1 variant="primary" class="my-0 w-100">
                <font-awesome-icon class="mr-2" :icon="iconClass"/>
                Exporter les données détaillées
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel" @show="updateIcon(true)" @hide="updateIcon(false)">
              <b-card-body class="px-0">
                <b-card-text class="d-flex">
                  <b-form-select v-model="selectedMonth" :options="monthsOptions" value-field="id"
                                 text-field="label" class="mr-2"></b-form-select>
                  <b-button @click="download()" variant="gray" class="float-right">
                    <font-awesome-icon icon="fa-solid fa-download"/>
                    Exporter
                  </b-button>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card no-body>
            <b-card-header class="p-0" role="tab">
              <b-button v-b-toggle.accordion-2 variant="primary" class="my-0 w-100">
                <font-awesome-icon class="mr-2"  :icon="iconSClass"/>
                Exporter la synthèse
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel" @show="updateSIcon(true)" @hide="updateSIcon(false)">
              <b-card-body class="px-0">
                <b-card-text class="d-flex">
                  <b-form-select v-model="selectedSMonth" :options="monthsOptions" value-field="id"
                                 text-field="label" class="mr-2"></b-form-select>
                  <b-button @click="download_synthese()" variant="gray" class="float-right">
                    <font-awesome-icon icon="fa-solid fa-download"/>
                    Exporter
                  </b-button>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>

        </div>

      </div>
    </div>
  </main>
</template>

<script>


import CustomFooter from "@/components/CustomFooter.vue";
import {dowload_report, dowload_synthese_report} from "@/services/ApiServices";
import {build_download, build_filename} from "@/utils/File";
import {getMonth, getPreviousMonths} from "@/utils/Months";

export default {
  name: 'home-boss-view',
  components: {CustomFooter},
  data() {
    return {
      iconClass: 'fas fa-chevron-right',
      iconSClass: 'fas fa-chevron-right',
      selectedMonth: null,
      selectedSMonth: null,
      monthsOptions: [],
    }
  },
  methods: {
    updateIcon(isOpen) {
      this.iconClass = isOpen ? 'fas fa-chevron-down' : 'fas fa-chevron-right';
    },
    updateSIcon(isOpen) {
      this.iconSClass = isOpen ? 'fas fa-chevron-down' : 'fas fa-chevron-right';
    },
    download() {
      dowload_report(this.selectedMonth).then(response => {
        if (response[0] == 200) {
          const filename = build_filename(getMonth(this.selectedMonth), "xlsx")
          build_download(response[1], filename)
        } else {
          // TODO display alert error
        }
      })
    },
    download_synthese() {
      dowload_synthese_report(this.selectedSMonth).then(response => {
        if (response[0] == 200) {
          const filename = build_filename("synthese_" + getMonth(this.selectedSMonth), "xlsx")
          build_download(response[1], filename)
        } else {
          // TODO display alert error
        }
      })
    }
  },
  created() {
    this.monthsOptions = getPreviousMonths(3);
    this.selectedMonth = this.monthsOptions[0].id
    this.selectedSMonth = this.monthsOptions[0].id
  }
}
</script>

<style lang="scss" scoped>

a, button {
  border-radius: 10px;
}

a, .custom-btn, .accordion {
  display: block;
  width: 100%;
  margin: 20px 0;
}

@media(min-width: 576px) {
  a, .custom-btn, .accordion {
    width: 35%;
  }
}

.alert {
  white-space: pre-wrap;
}

p {
  margin-bottom: 0;
  margin-top: 0;
}

.card-header {
  background-color: white;
}

.card {
  border: none;
  margin-bottom: 20px;
}

.custom-select {
  width: 60%;
}

</style>