<template>
  <main>
    <div class="main-container">

      <dismiss-alert :timer="5" variant="success" ref="deleteAlert"
                     msg="Le salarié a bien été supprimé"></dismiss-alert>

      <div class="h-100">
        <b-button class="l-btn" href="/user/create" variant="primary">
          <font-awesome-icon icon="fa-solid fa-plus"/>
          Créer un salarié
        </b-button>
        <div class="mt-4">

          <SearchBar @search-filter="update_filter" label="un salarié"></SearchBar>

          <b-card-group deck>
            <b-card v-for='item in get_filter_users()'>
              <b-card-text>
                <a v-b-modal.delete-modal class="commande"
                   style="width: auto;margin: 0 5px;cursor: pointer" @click="selected=item">
                  <font-awesome-icon icon="fa-solid fa-trash"/>
                </a>
                <p>Utilisateur: {{ item[1] }}</p>
                <p v-if="item[3]">Nom: {{ item[3] }} {{ item[4] }}</p>
                <p v-if="item[2]">Contact: {{ item[2] }} </p>
              </b-card-text>
            </b-card>
          </b-card-group>
        </div>

        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>

      </div>

    </div>

    <!-- Confirmation delete modal -->
    <b-modal id="delete-modal" centered>
      <h5 id="custom-title">Suppression</h5>
      <p class="mt-3">Etes vous sûr de vouloir supprimer l'utilisateur {{ selected[3] }} {{ selected[4] }}? </p>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('delete-modal')">Annuler</b-button>
        <b-button variant="primary" @click="delete_user">Valider</b-button>
      </template>
    </b-modal>

  </main>
</template>

<script>
import {delete_user, get_users} from "@/services/ApiServices";
import DismissAlert from "@/components/basics/DismissAlert.vue";
import SearchBar from "@/components/SearchBar.vue";


export default {
  name: 'user-view',
  components: {DismissAlert, SearchBar},
  data() {
    return {
      input: '',
      users: [],
      msg_alert: '',
      class_alert: '',
      currentPage: 1,
      perPage: 4,
      rows: 0,
      selected: []
    }
  },
  methods: {
    update_filter(n) {
      this.input = n
    },
    get_filter_users() { // filter with pagination
      return this.users.filter((user) => {
            const full_name = (user[3] + ' ' + user[4]).toLowerCase() // TODO check if exist
            return full_name.includes(this.input.toLowerCase())
          }
      ).slice(this.perPage * (this.currentPage - 1), this.perPage * (this.currentPage));
    },
    load_data() {
      get_users().then(response => {
        this.users = response.data;
        this.rows = response.data.length;
      }).catch(error => {
        console.log(error) // TODO display error message
      })
    },
    delete_user() {
      const id = this.selected[0]
      this.$bvModal.hide('delete-modal')
      delete_user(id).then(response => {
        this.load_data()
        this.$refs.deleteAlert.showAlert()
      }).catch(error => {
        console.log(error) // TODO display error message
      })
    }
  },
  created() {
    this.load_data()
  }
}
</script>

<style lang="scss" scoped>

.pagination {
  padding-bottom: 20px;
  width: fit-content;
  margin: auto;
}

a, .l-btn {
  width: 100%;
  margin: 10px 0;
  border-radius: 10px;
}

.card-deck .card {
  margin-bottom: 15px;
}

@media(min-width: 576px) {
  .card-deck .card, label {
    min-width: 35%;
    max-width: 35%;
  }
  a, .l-btn {
    width: 35%;
  }
}

.alert {
  white-space: pre-wrap;
}

p {
  margin-bottom: 0;
  margin-top: 0;
}

.commande {
  float: right;
  background-color: #d3d3d39c;
  padding: 5px 12px;
  border-radius: 7px;
}

#custom-title {
  border-bottom: 1px solid lightgray;
  padding: 5px 0px 13px 0px;
  font-weight: bolder;
}
</style>