<template>
  <main>
    <div class="main-container">
      <h2 class="mb-4 text-primary">Mentions Légales</h2>

      <section>
        <h5 class="my-3 text-primary subtitle">1. Identité du Propriétaire du Site</h5>
        <p>Raison Sociale: Simplifix Consulting©</p>
        <p>Forme Juridique: Entrepreneur Individuel</p>
        <p>Siège Social: 49B rue Parmentier, 94210 St-Maur des fossés</p>
        <p>Contact : monmail@test.fr </p> <!--TODO-->
      </section>

      <section>
        <h5 class="my-3 text-primary subtitle">2. Coordonnées de l'Hébergeur</h5>
        <p>Nom de l'Hébergeur: OVH Cloud</p>
        <p>RCS Lille Métropole 424 761 419 00045</p>
        <p>N° TVA : FR 22 424 761 419</p>
        <p>Siège social : 2 rue Kellermann - 59100 Roubaix - France</p>
      </section>

      <section>
        <h5 class="my-3 text-primary subtitle">3. Numéro d'Immatriculation</h5>
        <p>Numéro SIRET: 890 087 489 00013</p>
      </section>
    </div>
  </main>
</template>

<script>
export default {
  name: 'terms-view'
};
</script>

<style scoped>

p {
  margin-bottom: 0;
}

h4 {
  margin: 5px;
}

.subtitle {
  padding-bottom: 5px;
  text-decoration: underline;
  font-width: bolder;
}
</style>
