import axios from 'axios';
import {get_fetch_header, set_header} from "@/utils/Cookies";

export async function call_drive(url) {
    return await axios.get(process.env.VUE_APP_API_URL + url, set_header())
}

export async function download_drive(url) {
    const response = await fetch(process.env.VUE_APP_API_URL + url, get_fetch_header())
    return [response.status, await response.blob()];
}

export async function delete_drive(url) {
    return await axios.delete(process.env.VUE_APP_API_URL + url, set_header())
}

export async function post_drive(url, data) {
    const response = await axios.post(process.env.VUE_APP_API_URL + url, data, set_header());
    return await response.data;
}

// Declaration
export async function get_declarations(month) {
    return await call_drive('/declaration/all?month='+month);
}

export async function create_declaration(form) {
    return await post_drive('/declaration/create', form);
}

export async function delete_declaration(id) {
    return await delete_drive('/declaration/delete/' + id)
}

// Magasin
export async function get_magasins() {
    return await call_drive('/magasin/all');
}

export async function delete_magasin(id) {
    return await delete_drive('/magasin/delete/' + id)
}

export async function create_magasin(form) {
    return await post_drive('/magasin/create', form);
}

// Utilisateurs
export async function get_users() {
    return await call_drive('/user/all');
}

export async function load_user(){
    return await  call_drive('/user/get')
}

export async function delete_user(id) {
    return await delete_drive('/user/delete/' + id)
}

export async function create_user(form) {
    return await post_drive('/user/create', form);
}

export async function update_user(form) {
    return await post_drive('/user/update', form);
}

// Authentification
export async function auth_user(form) {
    return await post_drive('/user/auth', form);
}

// Export excel
export async function dowload_report(month){
    return await download_drive("/export/all?month="+month);
}

export async function dowload_synthese_report(month){
    return await download_drive("/export/consolidated?month="+month);
}