<template>
  <main>
    <div class="main-container">
      <h3 class="custom-title text-center text-primary mb-3">Mon compte</h3>

      <dismiss-alert v-for="err in errors" ref="errorAlert" :msg="err"></dismiss-alert>

      <!-- Form Declaration -->
      <b-form id="form_send">
        <p class="text-primary">Nom</p>
        <b-form-input v-model="user.nom" placeholder="Nom"></b-form-input>
        <p class="text-primary">Prénom</p>
        <b-form-input v-model="user.prenom" placeholder="Prénom"></b-form-input>
        <p class="text-primary">Contact</p>
        <b-form-textarea v-model="user.contact" placeholder="Contact"></b-form-textarea>
        <p class="text-primary">Nouveau mot de passe</p>
        <PasswordField @update-data="updatePassword" class="mt-2"></PasswordField>
        <b-button id="start" variant="primary" type="button" @click="send()" class="mt-2">Valider</b-button>
      </b-form>

    </div>
  </main>

</template>

<script>

import {load_user, update_user} from "@/services/ApiServices";
import DismissAlert from "@/components/basics/DismissAlert.vue";
import PasswordField from "@/components/PasswordField.vue";

export default {
  name: "account-view",
  components: {DismissAlert, PasswordField},
  data: () => {
    return {
      errors: [],
      user: {
        contact: '',
        nom: '',
        prenom: '',
        password: ''
      }
    }
  },
  methods: {
    updatePassword(n) {
      this.user.password=n
    },
    not_empty(field, msg) {
      // Check if field is not empty, if empty add error message
      if (!field) {
        this.errors.push(msg)
        return false
      }
      return true
    },
    async displayAlerts() {
      await this.$nextTick(); // permet d'attendre la fin du rendu du composant v-for b-alert
      this.$refs.errorAlert.forEach(alert => {
        alert.showAlert();
      });
    },
    validate_form() {
      // Check if all fields of the forms are valid, if not add error message toarray errors
      // Return true if form is valid else false
      this.errors = []
      this.not_empty(this.user.nom, "Entrez votre nom")
      this.not_empty(this.user.prenom, "Entrez votre prénom")
      this.not_empty(this.user.contact, "Entrez votre contact")

      return this.errors.length == 0
    },
    async send() {
      if (this.validate_form()) {
        update_user(this.user).then(response => {
          this.$router.push('/')
        }).catch(async error => {
          this.errors.push(error.response.data)
          await this.displayAlerts()
        })
      } else {
        await this.displayAlerts()
      }
    }
  },
  created() {
    load_user().then(response => {
      this.user = response.data[0]
    })
  }
}
</script>

<style lang="scss" scoped>

@import "../assets/styles/custom_variables";

p {
  margin-bottom: 0px;
}

span.float-right {
  cursor: pointer;
  margin-left: 5%;
}

input, textarea {
  margin: 10px 0;
}

select {
  margin: 10px 0;
}

.active {
  background-color: gray;
}

form {
  padding: 10px;
  background: #d3d3d39c;
  border-radius: 10px;
  max-width: 500px;
  margin: auto;
}
</style>