<template>
  <main>
    <div class="main-container">

      <dismiss-alert :timer="5" variant="success" ref="deleteAlert"
                     msg="Le client a bien été supprimé"></dismiss-alert>

      <div class="h-100">
        <b-button class="l-btn"  href="/magasin/create" variant="primary">
          <font-awesome-icon icon="fa-solid fa-plus"/>
          Créer un client
        </b-button>

        <div class="mt-4">
          <SearchBar @search-filter="update_filter" label="un client"></SearchBar>
          <b-card-group deck>
            <b-card v-for='item in get_filter_magasin()'>
              <b-card-text>

                <a v-b-modal.delete-magasin-modal class="commande" @click="selected=item"
                   style="width: auto;margin: 0 5px;cursor: pointer">
                  <font-awesome-icon icon="fa-solid fa-trash"/>
                </a>
                <p class="code">Code client: {{ item[0] }}</p>
                <p id="desc"> {{ item[1] }} </p>

              </b-card-text>
            </b-card>
          </b-card-group>
        </div>
        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
      </div>
    </div>

    <!-- Confirmation delete modal -->
    <b-modal id="delete-magasin-modal" centered>
      <h5 id="custom-title">Suppression</h5>
      <p class="mt-3">Etes vous sûr de vouloir supprimer ce client ? </p>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('delete-magasin-modal')">Annuler</b-button>
        <b-button variant="primary" @click="delete_magasin">Valider</b-button>
      </template>
    </b-modal>
  </main>
</template>

<script>
import {delete_magasin, get_magasins} from "@/services/ApiServices";
import DismissAlert from "@/components/basics/DismissAlert.vue";
import SearchBar from "@/components/SearchBar.vue";


export default {
  name: 'magasin-view',
  components: {DismissAlert, SearchBar},
  data() {
    return {
      input: '',
      magasins: [],
      msg_alert: '',
      class_alert: '',
      currentPage: 1,
      perPage: 4,
      rows: 0,
      selected: []
    }
  },
  methods: {
    update_filter(n) {
      this.input = n
    },
    load_data() {
      get_magasins().then(response => {
        this.magasins = response.data;
        this.rows = response.data.length;
      }).catch(error => {
        console.log(error) // TODO display error message
      })
    },
    get_filter_magasin() { // filter + pagination
      return this.magasins.filter((item) => { // filter in description + code magasin
            const f_item = item[0] + ' ' + item[1].toLowerCase()
            return f_item.includes(this.input.toLowerCase())
          }
      ).slice(this.perPage * (this.currentPage - 1), this.perPage * (this.currentPage));
    },
    delete_magasin() {
      const id = this.selected[0]
      this.$bvModal.hide('delete-magasin-modal')
      delete_magasin(id).then(response => {
        this.load_data()
        this.$refs.deleteAlert.showAlert()
      }).catch(error => {
        console.log(error) // TODO display error message
      })
    }
  },
  created() {
    this.load_data()
  }
}
</script>

<style lang="scss" scoped>

.pagination {
  padding-bottom: 20px;
  width: fit-content;
  margin: auto;
}

a, .l-btn {
  width: 100%;
  margin: 10px 0;
  border-radius: 10px;
}

.card-deck .card {
  margin-bottom: 15px;
}

@media(min-width: 576px) {
  .card-deck .card, label {
    min-width: 35%;
    max-width: 35%;
  }
  a, .l-btn {
    width: 35%;
  }
}

.alert {
  white-space: pre-wrap;
}

p {
  margin-bottom: 0;
  margin-top: 0;
}

.code, .commande {
  background-color: #d3d3d39c;
  padding: 5px 12px;
  border-radius: 7px;
}

.commande {
  float: right;
}

.code {
  width: fit-content;
}

#desc {
  margin-left: 7px;
  margin-top: 10px;
}

#custom-title {
  border-bottom: 1px solid lightgray;
  padding: 5px 0px 13px 0px;
  font-weight: bolder;
}
</style>