<template>
  <main>
    <div class="main-container">
      <h3 class="custom-title text-center text-primary mb-3">Ajouter un salarié</h3>

      <dismiss-alert v-for="err in errors" ref="errorAlert" :msg="err"></dismiss-alert>

      <!-- Form Declaration -->
      <b-form id="form_send">
        <p class="text-primary">Nom d'utilisateur</p>
        <b-form-input v-model="form.username" placeholder="Ex: 1ère lettre du prénom + nom"></b-form-input>
        <p class="text-primary">Nom</p>
        <b-form-input v-model="form.nom" placeholder="Nom"></b-form-input>
        <p class="text-primary">Prénom</p>
        <b-form-input v-model="form.prenom" placeholder="Prénom" ></b-form-input>
        <p class="text-primary">Contact</p>
        <b-form-textarea rows="3" v-model="form.contact" placeholder="Contact"></b-form-textarea>

        <b-button id="start" variant="primary" type="button" @click="send()" class="mt-2">Valider</b-button>
      </b-form>

    </div>
  </main>

</template>

<script>

import {create_declaration, create_user} from "@/services/ApiServices";
import DismissAlert from "@/components/basics/DismissAlert.vue";

export default {
  name: "create-article-view",
  components: {DismissAlert},
  data: () => {
    return {
      errors: [],
      form: {
        username: '',
        nom: '',
        prenom: '',
        contact: '',
      }
    }
  },
  methods: {
    not_empty(field, msg) {
      // Check if field is not empty, if empty add error message
      if (!field) {
        this.errors.push(msg)
        return false
      }
      return true
    },
    async displayAlerts() {
      await this.$nextTick(); // permet d'attendre la fin du rendu du composant v-for b-alert
      this.$refs.errorAlert.forEach(alert => {
        alert.showAlert();
      });
    },
    validate_form() {
      // Check if all fields of the forms are valid, if not add error message to array errors
      // Return true if form is valid else false
      this.errors = []
      const msg = "Veuillez renseigner"
      this.not_empty(this.form.username, msg +" un nom d'utilisateur")
      this.not_empty(this.form.nom, msg + " un nom")
      this.not_empty(this.form.prenom, msg + " un prénom")
      this.not_empty(this.form.contact, msg + " le champ contact")
      return this.errors.length == 0
    },
    async send() {
      // Send form to API if form is valid
      // if request success redirect to homepage
      if (this.validate_form()) {
        create_user(this.form).then(response => {
          this.$router.push('/user')
        }).catch(async error => {
          this.errors.push(error.response.data)
          await this.displayAlerts()
        })
      } else {
        await this.displayAlerts()
      }
    }
  }
}
</script>

<style lang="scss" scoped>

@import "../../assets/styles/custom_variables";

p {
  margin-bottom: 0px;
}

span.float-right {
  cursor: pointer;
  margin-left: 5%;
}

input {
  margin: 10px 0;
}

select {
  margin: 10px 0;
}

.active {
  background-color: gray;
}

form {
  padding: 10px;
  background: #d3d3d39c;
  border-radius: 10px;
  max-width: 500px;
  margin: auto;
}
</style>