import Cookies from 'js-cookie';

const ACCESS_TOKEN = 'access_token'
const IS_BOSS = 'is_boss'
const USERNAME = 'username'
const COOKIE_LIST = [ACCESS_TOKEN, IS_BOSS, USERNAME]

export function clear_cookies() {
    // Clear all cookies of the cookie list in cache
    COOKIE_LIST.forEach(item => {
        Cookies.remove(item)
    })
}

export function set_cookies(response) {
    // store response data  in cookie
    COOKIE_LIST.forEach(item => {
        Cookies.set(item, response[item], {expires: process.env.TOKEN_EXP})
    })
}

export function set_header() {
    //Add access token (jwt) from cookie in header for request
    const headers = {
        Authorization: `Bearer ${Cookies.get(ACCESS_TOKEN)}`,
    }
    return {headers}
}

export function is_connected() {
    return Cookies.get(ACCESS_TOKEN) !== undefined
}

export function is_boss() {
    // return True if user is boss
    return Cookies.get(IS_BOSS) == 1
}

export function get_username() {
    // return username from cookie storage
    return Cookies.get(USERNAME)
}

export function get_fetch_header() {
    return {
        headers: {
            Authorization: `Bearer ${Cookies.get(ACCESS_TOKEN)}`,
        }
    }
}