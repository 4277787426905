import Vue from "vue";
import VueRouter from "vue-router";
import CreateDeclarationView from "@/views/Form/CreateDeclarationView.vue";
import AuthView from "@/views/Form/AuthView.vue";
import {is_connected} from "@/utils/Cookies";
import MagasinView from "@/views/MagasinView.vue";
import UserView from "@/views/UserView.vue";
import CreateMagasinView from "@/views/Form/CreateMagasinView.vue";
import CreateUserView from "@/views/Form/CreateUserView.vue";
import HomeView from "@/views/Home/HomeView.vue";
import AccountView from "@/views/AccountView.vue";
import Terms from "@/views/Terms.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home-view',
        component: HomeView
    },
    {
        path: '/auth',
        name: 'auth-view',
        component: AuthView
    },
    {
        path: '/create/declaration',
        name: 'create-declaration-view',
        component: CreateDeclarationView,
    },
    {
        path: '/magasin',
        name: 'magasin-view',
        component: MagasinView,
    }, {
        path: '/magasin/create',
        name: 'create-magasin-view',
        component: CreateMagasinView,
    },
    {
        path: '/user',
        name: 'user-view',
        component: UserView,
    }, {
        path: '/user/create',
        name: 'create-user-view',
        component: CreateUserView,
    }, {
        path: '/account',
        name: 'account-view',
        component: AccountView
    }, {
        path: '/terms',
        name: 'terms-view',
        component: Terms
    }
];

const router = new VueRouter({
    mode: "history",
    routes
})

router.beforeEach((to, from, next) => {
    if (is_connected() || to.name == 'auth-view') {
        next()
    } else {
        next({name: 'auth-view'})
    }
})


export default router;
