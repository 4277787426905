<template>
  <div>
    <b-alert
        :show="dismissCountDown"
        dismissible
        :variant=variant
        @dismissed="dismissCountDown=0"
        @dismiss-count-down="countDownChanged"
    >
      {{msg}}
    </b-alert>
  </div>
</template>

<script>
export default {
  name:'dismiss-alert',
  data() {
    return {
      dismissSecs: this.timer,
      dismissCountDown: 0
    }
  },
  props: {
    variant: {type: String, default:"danger"}, // info, warning, danger,success
    msg: {type: String, default: "Champ manquant"},
    timer: {type: Number, default: 30}
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    }
  }
}
</script>