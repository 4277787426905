<template>
  <main>
    <div class="main-container">

      <dismiss-alert :timer="5" variant="success" ref="deleteAlert"
                     msg="La déclaration a bien été supprimée"></dismiss-alert>

      <div class="h-100">
        <b-button v-if="show_btn" class="l-btn" href="/create/declaration" variant="primary"> Nouvelle déclaration</b-button>
        </br>
        <div class="d-flex">
          <b-form-select @change="load_data()" v-model="selectedMonth" :options="monthsOptions" value-field="id"
                         text-field="label"></b-form-select>
          <p class="ml-2 commande">{{ counter }}</p>
        </div>

        <div class="mt-4">
          <b-card-group deck>
            <b-card v-for='item in declarations.slice(perPage*(currentPage-1),perPage*(currentPage))'>
              <b-card-text>
                <a v-if="show_btn" v-b-modal.delete-decl-modal class="commande" @click="selected=item"
                   style="width: auto;margin: 0 5px;cursor: pointer">
                  <font-awesome-icon icon="fa-solid fa-trash"/>
                </a>
                <p class="commande">{{ item[4] }}</p>
                <p>Date: {{ item[5] }}</p>
                <p>Heure: {{ item[3] }} </p>
                <p>Code client: {{ item[1] }}</p>
              </b-card-text>
            </b-card>
          </b-card-group>
        </div>

        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
      </div>

    </div>

    <!-- Confirmation delete modal -->
    <b-modal id="delete-decl-modal" centered>
      <h5 id="custom-title">Suppression</h5>
      <p class="mt-3">Etes vous sûr de vouloir supprimer cette déclaration: </p>
      <p>Le {{ selected[5] }} de {{ selected[3] }} ? </p>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('delete-decl-modal')">Annuler</b-button>
        <b-button variant="primary" @click="delete_declaration">Valider</b-button>
      </template>
    </b-modal>
  </main>
</template>

<script>
import {delete_declaration, get_declarations} from "@/services/ApiServices";
import {getPreviousMonths} from "@//utils/Months"
import DismissAlert from "@/components/basics/DismissAlert.vue";


export default {
  name: 'home-user-view',
  components: {DismissAlert},
  data() {
    return {
      declarations: [],
      msg_alert: '',
      class_alert: '',
      currentPage: 1,
      perPage: 4,
      rows: 0,
      selectedMonth: null,
      monthsOptions: [],
      counter: 0,
      selected: []
    }
  },
  computed: {
    show_btn: function() {
      return this.selectedMonth !== this.monthsOptions[2].id
    }
  },
  methods: {
    load_data() {
      get_declarations(this.selectedMonth).then(response => {
        this.declarations = response.data[1];
        this.rows = response.data.length;
        this.counter = response.data[0]
      }).catch(error => {
        console.log(error) // TODO display error message
      })
    },
    delete_declaration() {
      const id = this.selected[0]
      this.$bvModal.hide('delete-decl-modal')
      delete_declaration(id).then(response => {
        this.load_data()
        this.$refs.deleteAlert.showAlert()
      }).catch(error => {
        console.log(error) // TODO display error message
      })
    }
  },
  created() {
    this.monthsOptions = getPreviousMonths(3);
    this.selectedMonth = this.monthsOptions[0].id
    this.load_data()
  }
}
</script>

<style lang="scss" scoped>

.pagination {
  padding-bottom: 20px;
  width: fit-content;
  margin: auto;
}

a, .l-btn {
  width: 100%;
  margin: 10px 0;
  border-radius: 10px;
}

.card-deck .card {
  margin-bottom: 15px;
}

@media(min-width: 576px) {
  .card-deck .card {
    min-width: 35%;
    max-width: 35%;
  }
  a, .l-btn, select {
    width: 35%;
  }
}

.alert {
  white-space: pre-wrap;
}

p {
  margin-bottom: 0;
  margin-top: 0;
}

.commande {
  float: right;
  background-color: #d3d3d39c;
  padding: 5px 12px;
  border-radius: 7px;
}
</style>