<template>
  <div id="app">
    <home-navbar v-if="$route.name !== 'auth-view'"></home-navbar>

    <keep-alive>
      <router-view/>
    </keep-alive>
    <CustomFooter/>
  </div>

</template>

<style lang="scss" scoped>

#app {
  height: 100%;
}
</style>

<script>
import HomeNavbar from "@/components/HomeNavbar.vue";
import CustomFooter from "@/components/CustomFooter.vue";

export default {
  name: 'App',
  components: {HomeNavbar, CustomFooter},
  methods: {},
  created() {
    document.title = "Feuille d'heure";
  }
}

// TODO :
// Possibilitée de paramétrer le excel
// Mettre une limite sur le nombre max de salariés + message erreur  + paramétrable dans fichier d'environnement en fonction de la formule choisie
// simplifier choix de l'heure 15min
//Ajout mdp oublié
//purge des données de + 6 mois
// Ajouter export excel clients
// Check cookie download
// Ajout statut supprimé employé pour ne pas perdre les déclarations existantes : suppression effective quand il n'y a plus de déclarations contenant son ID (à faire lors de la purge)
// Interface admin pour accéder à la base de donnée pour restaurer en cas d'erreur + historique des commandes sql ?
// Ajout bouton "Ajouter sur l'écran d'accuei" pwa
//Ajouter "Exporter mes déclarations (en pdf ?)"
</script>