<template>
  <label class="w-100 d-flex">
    <font-awesome-icon class="glass" icon="fa-solid fa-magnifying-glass"/>
    <input type="text" @input="updateFilter" v-model="input" class="search-bar"
           :placeholder="placeholder"/>
  </label>
</template>

<script>
export default {
  name: "SearchBar",
  data() {
    return {
      input: '',
    }
  },
  props: {
    label: String
  },
  computed: {
    placeholder() {
      return `Chercher ${this.label}...`;
    }
  },
  methods: {
    updateFilter() {
      this.$emit('search-filter', this.input);
    }
  }
}
</script>

<style scoped lang="scss">
.search-bar {
  width: 100%;
  border-radius: 12px;
  padding: 10px 10px 10px 35px;
  border: solid 1px lightgray;
  margin-bottom: 15px;
}

.glass {
  z-index: 1;
  margin: 14px -29px 0px 12px;
  color: gray;
}

input:focus {
  outline-color: gray;
}
</style>