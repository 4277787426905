import Vue from 'vue'

// import scss
import './assets/styles/index.scss'

import App from './App.vue'
import router from './router'
import {BootstrapVue, CardPlugin, FormFilePlugin, FormPlugin, FormSelectPlugin, PaginationPlugin} from 'bootstrap-vue'
//Font awesome
import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {
    faArrowRightFromBracket,
    faBriefcase,
    faDownload,
    faEye,
    faEyeSlash,
    faHouse,
    faMagnifyingGlass,
    faPencil,
    faPlus,
    faTrash,
    faUser,
    faUsers,
    faChevronRight,
    faChevronDown
} from '@fortawesome/free-solid-svg-icons'
//Spinner: https://github.com/pacifio/vue-spinners
import VueSpinners from 'vue-spinners'
// V-viewer
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';


Vue.use(BootstrapVue)

Vue.component('font-awesome-icon', FontAwesomeIcon)
library.add(faDownload, faMagnifyingGlass, faTrash, faUser, faPencil, faPlus, faEye, faEyeSlash,
    faArrowRightFromBracket, faHouse, faUsers, faBriefcase, faChevronRight, faChevronDown)

Vue.use(VueSpinners)
Vue.use(VueViewer)
Vue.use(PaginationPlugin)
Vue.use(CardPlugin)
Vue.use(FormPlugin)
Vue.use(FormFilePlugin)
Vue.use(FormSelectPlugin)

Vue.component('vue-typeahead-bootstrap', VueTypeaheadBootstrap)

// Ajouts des filtres globaux
export function capitalize(value) {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
}

Vue.filter('capitalize', function (value) {
    return capitalize(value)
})

new Vue({
    router,
    render: (h) => h(App)
}).$mount('#app')