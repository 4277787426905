<template>
  <HomeBossView v-if="is_boss()"></HomeBossView>
  <HomeUserView v-else></HomeUserView>
</template>

<script>

import {is_boss} from "@/utils/Cookies";
import HomeBossView from "@/views/Home/HomeBossView.vue";
import HomeUserView from "@/views/Home/HomeUserView.vue";

export default {
  name: 'home-view',
  components: {HomeBossView, HomeUserView},
  methods: {
    is_boss
  }
}
</script>