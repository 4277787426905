<script>
export default {
  name: "CustomFooter"
}
</script>

<template>
  <footer class="main">
    <p>© 2024 Feuille d'heure, Simplifix Consulting v.1.0.0</p>
    <a class="m-auto d-block" href="/terms">Mentions légales</a>
  </footer>
</template>

<style scoped lang="scss">

p, a {
  text-align: center;
  color: rgba(129, 126, 126, 0.75);
  font-size: 12px;
  margin-bottom: 0;
}

a {
  font-weight: bolder;
  text-decoration: underline;
}
</style>